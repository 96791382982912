import React from "react";
import { ESSENTIAL_FEATURES, EssentialFeature } from "../data/_PricingData";
import { TypographyV2 } from "components";
import * as styles from "./_EssentialFeaturesCard.module.scss";

const _EssentialFeaturesCard = () => {
	return (
		<div className={styles.essentialsContainer}>
			<TypographyV2
				variant={"HEADING_2"}
				tabletVariant="HEADING_3"
				mobileVariant="HEADING_4"
				weight="MEDIUM"
				className={styles.essentialstitle}
			>
				Essential features for every lab
			</TypographyV2>

			<main className={styles.essentialcard}>
				{ESSENTIAL_FEATURES.map((items, index) => {
					return <Feature key={index} {...items} />;
				})}
			</main>
		</div>
	);
};

export default _EssentialFeaturesCard;

const Feature = ({ icon, name, subtitle }: EssentialFeature) => {
	return (
		<main className={styles.feature}>
			<img src={icon} width={60} height={60} alt={name} />
			<div className={styles.card}>
				<TypographyV2
					variant="BODY_TEXT_EXTRA_LARGE"
					weight="SEMIBOLD"
					color="brand-600"
					className={styles.name}
				>
					{name}
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_EXTRA_LARGE"
					weight="REGULAR"
					color="text-helper"
					className={styles.subtitle}
				>
					{subtitle}
				</TypographyV2>
			</div>
		</main>
	);
};
