import { useState } from "react";
import { Button, TypographyV2 } from "@components/index";
import classNames from "classnames";
import React from "react";
import { PricingCardProps } from "../data/_PricingData";
import Icon from "./Icon";
import * as styles from "./PricingCard.module.scss";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { navigate } from "gatsby";

type Props = {
	card: PricingCardProps;
};

const PricingCard = ({ card }: Props) => {
	const [hoveredFeature, setHoveredFeature] = useState<string | null>(null);

	const [selectedPlan, setSelectedPlan] = useState<string>("");

	const determineSelectedPlan = (cardName: string | undefined) => {
		switch (cardName) {
			case "Team":
				return "Industry - Team";
			case "Business":
				return "Industry - Business";
			default:
				return "Industry - Enterprise";
		}
	};

	const handleSelected = () => {
		const plan = determineSelectedPlan(card?.name);
		setSelectedPlan(plan);
		navigate("/demo", { state: { selectedPlan: plan } });
	};

	return (
		<main style={{ position: "relative" }}>
			{card?.popular && (
				<div className={styles.popular}>
					<TypographyV2 className={styles.popularText}>
						Most popular
					</TypographyV2>
				</div>
			)}
			<div
				className={classNames(styles.pricingCard, {
					[styles.popularCard]: card?.popular,
					[styles.lastCard]: card?.last,
				})}
			>
				<section className={styles.carduppersection}>
					<div className={styles.cardUpperInner}>
						<TypographyV2
							color="marine"
							variant="HEADING_2"
							className={styles.pricingTypeName}
						>
							{card?.name}
						</TypographyV2>

						<TypographyV2
							variant="BODY_TEXT_MEDIUM"
							weight="REGULAR"
							color="text-helper"
							className={styles.subtitle}
						>
							{card?.subtitle}
						</TypographyV2>

						<TypographyV2
							variant="BODY_TEXT_MEDIUM"
							weight="REGULAR"
							color="text-helper"
							className={styles.seats}
						>
							{card?.seats}
						</TypographyV2>
					</div>

					<div className={styles.buttoncontainer}>
						<Button
							link="DEMO"
							style={{ marginBottom: 16 }}
							onClick={handleSelected} // Add click handler here
							className={classNames(styles.button, {
								[styles.popularBtn]: card?.popular,
							})}
						>
							{card?.callToActionText}
						</Button>
					</div>
				</section>
				<div className={styles.cardLowerSection}>
					<TypographyV2
						variant="BODY_TEXT_MEDIUM"
						color="text-helper"
						className={styles.advancedFeatureTextHeader}
					>
						{card?.advancedFeatureText}
					</TypographyV2>
					<div className={styles.advancedFeaturesContainer}>
						{card?.advancedFeatures.map((feature, index) => (
							<Tooltip
								key={index}
								placement="top"
								overlay={
									<div className={styles.tooltipInner}>
										{feature.tooltip}
									</div>
								}
								overlayInnerStyle={{
									background: "#111322",
									borderRadius: "8px",
									padding: "20px",
								}}
								overlayClassName={styles.tooltip}
								visible={
									hoveredFeature === feature.name &&
									!!feature.tooltip
								}
							>
								<div
									className={styles.advancedFeauturesText}
									onMouseEnter={() =>
										setHoveredFeature(feature.name)
									}
									onMouseLeave={() => setHoveredFeature(null)}
								>
									<div className={styles.icon}>
										<Icon name={feature.icon} />
									</div>
									<div>
										<TypographyV2
											variant="BODY_TEXT_MEDIUM"
											weight="REGULAR"
											color="text-helper"
											className={styles.featureText}
										>
											{feature.name}
										</TypographyV2>
										{feature.underline && (
											<div
												className={styles.underline}
											></div>
										)}
									</div>
								</div>
							</Tooltip>
						))}
					</div>
				</div>
				{card?.popular && (
					<section>
						<div className={styles.line}></div>
						<TypographyV2
							variant="BODY_TEXT_MEDIUM"
							weight="BOLD"
							color="text-helper"
							className={styles.advancedFeatureTextHeader}
						>
							{card?.advancedFeatureText2}
						</TypographyV2>
						<div className={styles.advancedFeaturesContainer}>
							{card?.advancedFeatures2.map((feature, index) => (
								<Tooltip
									key={index}
									placement="top"
									overlay={
										<div className={styles.tooltipInner}>
											{feature.tooltip}
										</div>
									}
									overlayInnerStyle={{
										background: "#111322",
										borderRadius: "8px",
										padding: "20px",
									}}
									overlayClassName={styles.tooltip}
									visible={
										hoveredFeature === feature.name &&
										!!feature.tooltip
									}
								>
									<div
										className={styles.advancedFeauturesText}
										onMouseEnter={() =>
											setHoveredFeature(feature.name)
										}
										onMouseLeave={() =>
											setHoveredFeature(null)
										}
									>
										<Icon name={feature.icon} />
										<div>
											<TypographyV2
												variant="BODY_TEXT_MEDIUM"
												weight="REGULAR"
												color="text-helper"
												className={styles.featureText}
											>
												{feature.name}
											</TypographyV2>
											{feature.underline && (
												<div
													className={styles.underline}
												></div>
											)}
										</div>
									</div>
								</Tooltip>
							))}
						</div>
					</section>
				)}
			</div>
		</main>
	);
};

export default PricingCard;
