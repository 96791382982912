import { Typography } from "@components/index";
import React from "react";
import { IconNames } from "../common/Icon";
import Freezer from "../common/assets/Freezer.svg";
import pricingorders from "../common/assets/pricing orders.svg";
import pricingreports from "../common/assets/pricing reports.svg";
import pricingconsumables from "../common/assets/pricing-consumables.svg";
import pricingschedules from "../common/assets/pricing-schedule.svg";
import pricingtracking from "../common/assets/pricing-tracking.svg";
// import { Tooltip } from "antd";

type Feature = {
	icon: IconNames;
	name: string;
	tooltip?: string;
	beta?: boolean;
	strikethrough?: boolean;
	disabledIcon?: IconNames;
	underline?: boolean;
};

export type PricingCardProps = {
	name: string;
	subtitle: string;
	seats: string;
	callToActionText: string;
	callToActionTextDescription: string;
	users: { limit: number; threshold: number | null };
	featuresTitle: JSX.Element;
	features: Feature[];
	advancedFeatures: Feature[];
	advancedFeatures2: Feature[];
	advancedFeatureText?: string;
	advancedFeatureText2?: string;
	popular?: boolean;
	last?: boolean;
};

export const PRICING_CARDS: PricingCardProps[] = [
	{
		name: "Team",
		subtitle:
			"Best for startups looking to digitize sample inventory and experiments.",
		seats: "Includes 5 seats",
		callToActionText: "Request demo",
		callToActionTextDescription: "",
		users: { limit: 1, threshold: 10 },
		featuresTitle: (
			<Typography variant="SUBHEADERDESCBOLD">Access to</Typography>
		),
		features: [
			{
				icon: "freezer",
				name: "Inventory",
			},
			{
				icon: "notebook",
				name: "Notebook",
			},
			{
				icon: "order-management",
				name: "Orders",
			},
			{
				icon: "protocol-library",
				name: "Protocol library",
			},
			{
				icon: "stock-tracker",
				name: "Stock tracker",
				strikethrough: true,
				disabledIcon: "stock-tracker-disabled",
			},
			{
				icon: "consumables",
				name: "Consumables",
				strikethrough: true,
				disabledIcon: "consumables-disabled",
			},
			{
				icon: "admin-console",
				name: "Admin console",
				strikethrough: true,
				disabledIcon: "admin-console-disabled",
			},
			{
				icon: "multi-workspace",
				name: "Multi workspace",
				strikethrough: true,
				disabledIcon: "multi-workspace-disabled",
			},
		],
		advancedFeatures: [
			{
				icon: "advanced-search",
				name: "Basic inventory and ELN features",
			},
			{
				icon: "account-management",
				name: "Up to 3 virtual freezers",
			},

			{
				icon: "advanced-search",
				name: "Up to 6 notebooks and unlimited experiments",
			},
		],
		advancedFeatures2: [],
		advancedFeatureText: "What's included:",
		advancedFeatureText2: "",
	},
	{
		name: "Business",
		subtitle:
			"A R&D hub for scientists to streamline data, discovery, and handoffs.",
		seats: "Starts at 10 seats",
		callToActionText: "Request demo",
		callToActionTextDescription: "Apply for academia",
		users: { limit: 11, threshold: 50 },
		featuresTitle: (
			<Typography>
				Everything in <b>Team</b>, plus
			</Typography>
		),
		features: [
			{
				icon: "freezer",
				name: "Inventory",
			},
			{
				icon: "notebook",
				name: "Notebook",
			},
			{
				icon: "order-management",
				name: "Orders",
			},
			{
				icon: "protocol-library",
				name: "Protocol library",
			},
			{
				icon: "stock-tracker",
				name: "Stock tracker",
				disabledIcon: "stock-tracker-disabled",
			},
			{
				icon: "consumables",
				name: "Consumables",
				disabledIcon: "consumables-disabled",
			},
			{
				icon: "admin-console",
				name: "Admin console",
				strikethrough: true,
				disabledIcon: "admin-console-disabled",
			},
			{
				icon: "multi-workspace",
				name: "Multi workspace",
				strikethrough: true,
				disabledIcon: "multi-workspace-disabled",
			},
		],
		advancedFeatures: [
			{
				icon: "edit",
				name: "Additional freezers and notebooks",
			},
			{
				icon: "edit",
				name: "Order management ",
				underline: true,
				tooltip:
					"Collaborate with your team with a centralized order management platform that integrates with your inventory.",
			},
			{
				icon: "edit",
				name: "Consumables inventory",
				underline: true,
				tooltip:
					"Manage consumables precisely with a visualized inventory layout. Quickly find items using advanced search and filters.",
			},
			{
				icon: "edit",
				name: "Stock tracking automation",
				underline: true,
				tooltip:
					"Receive real-time updates and notifications on inventory stock levels.",
			},
			{
				icon: "edit",
				name: "Equipment management (beta)",
			},
		],
		advancedFeatures2: [
			{
				icon: "edit",
				name: "Custom item types",
				underline: true,
				tooltip:
					"Create and manage unique item types tailored to your lab. Customize fields for precise tracking and organization.",
			},
			{
				icon: "edit",
				name: "Data import/export",
			},
			{
				icon: "edit",
				name: "Analytics dashboard",
			},
			{
				icon: "edit",
				name: "Version control",
				underline: true,
				tooltip:
					"Track changes and maintain a history of all modifications. Ensure data integrity with a detailed trail of every update.",
			},
			{
				icon: "edit",
				name: "Activity log",
			},
		],
		advancedFeatureText: "Everything in Team, plus:",
		advancedFeatureText2: "Admin controls",
		popular: true,
	},
	{
		name: "Enterprise",
		subtitle:
			"For growing teams that need additional security and customizations.",
		seats: "Custom",
		callToActionText: "Request Demo",
		callToActionTextDescription: "",
		users: { limit: 51, threshold: null },
		featuresTitle: (
			<Typography>
				Everything in <b>Business</b>, plus
			</Typography>
		),
		features: [
			{
				icon: "freezer",
				name: "Inventory",
			},
			{
				icon: "notebook",
				name: "Notebook",
			},
			{
				icon: "order-management",
				name: "Orders",
			},
			{
				icon: "protocol-library",
				name: "Protocol library",
			},
			{
				icon: "stock-tracker",
				name: "Stock tracker",
			},
			{
				icon: "consumables",
				name: "Consumables",
			},
			{
				icon: "admin-console",
				name: "Admin console",
			},
			{
				icon: "multi-workspace",
				name: "Multi workspace",
			},
		],
		advancedFeatures: [
			{
				icon: "account-management",
				name: "Unlimited freezers and notebooks",
			},
			{
				icon: "account-management",
				name: "Multi workspaces",
				underline: true,
				tooltip:
					"Manage multiple labs within a single platform. Switch seamlessly between workspaces for improved collaboration.",
			},

			{
				icon: "account-management",
				name: "Custom integrations",
			},
			{
				icon: "account-management",
				name: "Security and compliance",
				underline: true,
				tooltip:
					"Compliant with SOC2, GDPR, CFR Part 11 (FDA), and HIPAA using AWS. Protects data with roles, permissions, and a detailed audit trail.",
			},
			{
				icon: "account-management",
				name: "Dedicated R&D manager",
				underline: true,
				tooltip:
					"Get personalized support for onboarding and implementation. Ensure a smooth transition and efficient system usage.",
			},
		],
		advancedFeatures2: [],
		advancedFeatureText: "Everything in Business, plus:",
		advancedFeatureText2: "",
		last: true,
	},
];

interface EssentialFeature {
	icon: string;
	name: string;
	subtitle: string;
}

export const ESSENTIAL_FEATURES: EssentialFeature[] = [
	{
		icon: Freezer,
		name: "Visualize freezer samples",
		subtitle:
			"Digitize lab freezers and track samples, minimizing manual logging time and reducing human errors.",
	},
	{
		icon: pricingconsumables,
		name: "Manage consumables",
		subtitle:
			"Manage lab consumables effectively with Genemod LIMS. Visualize lab space layouts for precise inventory management.",
	},
	{
		icon: pricingorders,
		name: "Manage orders",
		subtitle:
			"Simplify order management with Genemod to streamline your processes within a centralized and customizable LIMS platform.",
	},
	{
		icon: pricingtracking,
		name: "Search and track samples",
		subtitle:
			"Boost your efficiency with our centralized inventory system, featuring a powerful advanced search tool.",
	},
	{
		icon: pricingreports,
		name: "Protocols and reports",
		subtitle:
			"Create and manage research reports and protocols in a central hub. Our ELN offers a user-friendly digital lab workspace.",
	},
	{
		icon: pricingschedules,
		name: "Simplify equipment scheduling",
		subtitle:
			"Visualize equipment availability, and experience enhanced efficiency with our user-friendly equipment booking tool.",
	},
];
