// extracted by mini-css-extract-plugin
export var commonPricingComponentContainer = "_CommonPricingPage-module--commonPricingComponentContainer--z7wlE";
export var commonPricingMargin = "_CommonPricingPage-module--commonPricingMargin--LcTAu";
export var limitWidthOnLargeScreens = "_CommonPricingPage-module--limitWidthOnLargeScreens--7ZUul";
export var logoTabletClass = "_CommonPricingPage-module--logoTabletClass--a2jpf";
export var pricingCardBackground = "_CommonPricingPage-module--pricingCardBackground--3At8N";
export var pricingCards = "_CommonPricingPage-module--pricingCards--1HRy5";
export var pricingCardsBody = "_CommonPricingPage-module--pricingCardsBody--bZwM+";
export var pricingPageWidthWrapper = "_CommonPricingPage-module--pricingPageWidthWrapper--iRpjr";
export var pricingcardsMargin = "_CommonPricingPage-module--pricingcardsMargin--RNTtf";
export var subtitle = "_CommonPricingPage-module--subtitle--USsvy";
export var title = "_CommonPricingPage-module--title--ZWF2d";