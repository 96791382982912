// extracted by mini-css-extract-plugin
export var advancedFeatureTextHeader = "PricingCard-module--advancedFeatureTextHeader--17UUP";
export var advancedFeaturesContainer = "PricingCard-module--advancedFeaturesContainer--tIcn6";
export var advancedFeauturesText = "PricingCard-module--advancedFeauturesText--P5nG8";
export var button = "PricingCard-module--button--0WY8a";
export var buttoncontainer = "PricingCard-module--buttoncontainer--8Jb96";
export var callToActionDescription = "PricingCard-module--callToActionDescription--3k8ot";
export var cardLowerSection = "PricingCard-module--cardLowerSection--AGwBO";
export var cardUpperInner = "PricingCard-module--cardUpperInner--00TkH";
export var carduppersection = "PricingCard-module--carduppersection--IErj6";
export var featureText = "PricingCard-module--featureText--XHyQx";
export var icon = "PricingCard-module--icon---OfgX";
export var lastCard = "PricingCard-module--lastCard--H7u5H";
export var limitWidthOnLargeScreens = "PricingCard-module--limitWidthOnLargeScreens--Z5UuW";
export var line = "PricingCard-module--line--tdJ3m";
export var popular = "PricingCard-module--popular--Lzzxp";
export var popularBtn = "PricingCard-module--popularBtn--9EBK2";
export var popularCard = "PricingCard-module--popularCard--ZFJ+F";
export var popularText = "PricingCard-module--popularText--58St6";
export var pricingCard = "PricingCard-module--pricingCard--zSzLc";
export var pricingTypeName = "PricingCard-module--pricingTypeName--M89ll";
export var seats = "PricingCard-module--seats--HWkxC";
export var subtitle = "PricingCard-module--subtitle--ybAUi";
export var tooltip = "PricingCard-module--tooltip--iiZD3";
export var tooltipArrow = "PricingCard-module--tooltipArrow--iU3z9";
export var tooltipInner = "PricingCard-module--tooltipInner--7Czyi";
export var underline = "PricingCard-module--underline--5o9ae";