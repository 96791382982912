import React, { useState } from "react";
import cx from "classnames";
import * as styles from "./_CommonPricingPage.module.scss";
import { PageLayout, TypographyV2 } from "components";

import PricingCard from "./PricingCard";
import { PRICING_CARDS } from "../data/_PricingData";

import Margins from "@components/Margins/Margins";
import _EssentialFeaturesCard from "./_EssentialFeaturesCard";

import TrustedByV2 from "../../../components/TrustedByV2/TrustedByV2";
import JoinLeadersTestimonials from "@components/JoinLeadersTestimonials/JoinLeadersTestimonials";
import _AcademiaAndContact from "./_AcademiaAndContact";

const CommonPricingPage = () => {
	const [isCustomized, setIsCustomized] = useState(false);
	return (
		<main>
			<PageLayout
				defaultHeader="light"
				seoOptions={{ type: "PREDEFINED", pageName: "pricing" }}
				hideOverflowX={false}
			>
				<div
					style={{
						background: "var(--background)",
						width: "100%",
					}}
				>
					<Margins className={styles.margins}>
						<div
							style={{ maxWidth: "1400px" }}
							className={styles.commonPricingComponentContainer}
						>
							<div className={styles.commonPricingMargin}>
								<TypographyV2
									variant={"HEADING_1"}
									weight="MEDIUM"
									className={styles.title}
								>
									Make teamwork more productive
								</TypographyV2>
								<TypographyV2
									variant="BODY_TEXT_EXTRA_LARGE"
									weight="REGULAR"
									color="text-helper"
									className={styles.subtitle}
								>
									Modern software solutions built for
									scientific research teams of all sizes
								</TypographyV2>
								<div className={styles.pricingcardsMargin}>
									<PricingCards
										isCustomized={isCustomized}
										setIsCustomized={() =>
											setIsCustomized(!isCustomized)
										}
									/>
								</div>
							</div>

							<_AcademiaAndContact />
							<_EssentialFeaturesCard />
						</div>
					</Margins>
				</div>
				<div
					style={{
						width: "100%",
						maxWidth: "1400px",
						margin: "auto",
					}}
				>
					<TrustedByV2
						trustedByContainerClass={styles.trustedByContainerClass}
						logosContainerClass={styles.logosContainerClass}
						logoTabletClass={styles.logoTabletClass}
						logoClass={styles.logoClass}
					/>
				</div>
				<JoinLeadersTestimonials />
			</PageLayout>
		</main>
	);
};

const PricingCards = () => {
	return (
		<div className={styles.pricingCards}>
			{PRICING_CARDS.map((card, index) => (
				<PricingCard key={index} card={card} />
			))}
		</div>
	);
};

export default CommonPricingPage;
