import React from "react";
import { TypographyV2 } from "@components/index";
import Link from "@components/Link/Link";
import Button from "@components/Button_new/Button";
import * as styles from "./_AcademiaAndContact.module.scss";

const AcademiaAndContactData = [
	{
		title: "Academia and nonprofit",
		description:
			"Benefit from substantial yearly discounts specifically designed for research labs and nonprofit teams. Please note that this offer is application-based only, meaning you must apply and qualify to receive these special rates.",
		linkText: "Apply now",
		link: "application/academia",
	},
	{
		title: "Contact us",
		description:
			"Reach out to us to explore and discuss specialized pricing tailored for academic departments, including packages for resellers and various other pricing options. We are here to provide customized solutions that fit your unique needs and requirements.",
		linkText: "Contact us",
		link: "contact-us",
	},
];

const _AcademiaAndContact = () => {
	return (
		<main>
			<section className={styles.academiaAndContact}>
				{AcademiaAndContactData.map((item, index) => {
					const { title, description, linkText, link } = item;
					return (
						<div key={index} className={styles.container}>
							<TypographyV2
								color="brand-500"
								variant="HEADING_2"
								weight="BOLD"
								className={styles.title}
							>
								{title}
							</TypographyV2>
							<TypographyV2
								color="text-helper"
								variant="BODY_TEXT_MEDIUM"
								weight="REGULAR"
								className={styles.description}
							>
								{description}
							</TypographyV2>
							<div className={styles.link}>
								<Link to={link} className={styles.linkText}>
									{linkText}
								</Link>
							</div>
						</div>
					);
				})}
			</section>
		</main>
	);
};

export default _AcademiaAndContact;
